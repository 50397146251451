// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/aidenkwok/Documents/GitHub/aidenkwok.co.uk/website/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-project-jsx": () => import("/Users/aidenkwok/Documents/GitHub/aidenkwok.co.uk/website/src/templates/project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */),
  "component---src-pages-404-jsx": () => import("/Users/aidenkwok/Documents/GitHub/aidenkwok.co.uk/website/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("/Users/aidenkwok/Documents/GitHub/aidenkwok.co.uk/website/src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("/Users/aidenkwok/Documents/GitHub/aidenkwok.co.uk/website/src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-developer-jsx": () => import("/Users/aidenkwok/Documents/GitHub/aidenkwok.co.uk/website/src/pages/developer.jsx" /* webpackChunkName: "component---src-pages-developer-jsx" */),
  "component---src-pages-digital-jsx": () => import("/Users/aidenkwok/Documents/GitHub/aidenkwok.co.uk/website/src/pages/digital.jsx" /* webpackChunkName: "component---src-pages-digital-jsx" */),
  "component---src-pages-digitalorphysical-jsx": () => import("/Users/aidenkwok/Documents/GitHub/aidenkwok.co.uk/website/src/pages/digitalorphysical.jsx" /* webpackChunkName: "component---src-pages-digitalorphysical-jsx" */),
  "component---src-pages-flick-jsx": () => import("/Users/aidenkwok/Documents/GitHub/aidenkwok.co.uk/website/src/pages/flick.jsx" /* webpackChunkName: "component---src-pages-flick-jsx" */),
  "component---src-pages-index-jsx": () => import("/Users/aidenkwok/Documents/GitHub/aidenkwok.co.uk/website/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-print-jsx": () => import("/Users/aidenkwok/Documents/GitHub/aidenkwok.co.uk/website/src/pages/print.jsx" /* webpackChunkName: "component---src-pages-print-jsx" */),
  "component---src-pages-printorsculpture-jsx": () => import("/Users/aidenkwok/Documents/GitHub/aidenkwok.co.uk/website/src/pages/printorsculpture.jsx" /* webpackChunkName: "component---src-pages-printorsculpture-jsx" */),
  "component---src-pages-sculpture-jsx": () => import("/Users/aidenkwok/Documents/GitHub/aidenkwok.co.uk/website/src/pages/sculpture.jsx" /* webpackChunkName: "component---src-pages-sculpture-jsx" */),
  "component---src-pages-work-jsx": () => import("/Users/aidenkwok/Documents/GitHub/aidenkwok.co.uk/website/src/pages/work.jsx" /* webpackChunkName: "component---src-pages-work-jsx" */)
}

exports.data = () => import("/Users/aidenkwok/Documents/GitHub/aidenkwok.co.uk/website/.cache/data.json")

